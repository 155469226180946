body {
  margin: 0;
}

.map {
  position: absolute;
}

.main-div {
  display: flex;
  justify-content: center;
}

.header {
  height: 12vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: larger;
}

.table {
  width: 100vw;
  padding: 0 10vw;
}
